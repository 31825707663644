@charset "UTF-8";
/* ==========================================================================
  ELEMENT RESET
========================================================================== */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}
dd {
  margin-left: 0;
}
ol,
ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a {
  outline: 0;
}
a:focus,
button:focus {
  outline: 0;
}
em {
  font-style: normal;
}
figure {
  margin: 0;
}
table {
  border-collapse: collapse;
}
button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}
button:focus {
  outline: none;
}
input,
button,
select,
textarea {
  line-height: inherit;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  line-height: inherit;
}
hr {
  margin: 0;
}
img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
/* ==========================================================================
  UTILL
========================================================================== */
/* 画像置換 */
.u-ir {
  display: block;
  overflow: hidden;
  font: 0/0 a;
  white-space: nowrap;
  text-indent: 100%;
}

/* PC、タブレットで画像置換 */
@media all and (min-width: 768px), print {
  .u-ir--tab-pc {
    display: block;
    overflow: hidden;
    font: 0/0 a;
    white-space: nowrap;
    text-indent: 100%;
  }
}
/* --------------------------------------------------------------------------
  svg
-------------------------------------------------------------------------- */
.c-svg {
  position: relative;
  display: block;
  width: 100%;
}
.c-svg::before {
  display: block;
  padding-top: 100%;
  content: '';
}
.c-svg > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* --------------------------------------------------------------------------
  非表示
-------------------------------------------------------------------------- */
/* PCで非表示 */
@media all and (min-width: 1201px), print {
  .u-hide-pc {
    display: none;
  }
}
/* PC、タブレットで非表示 */
@media all and (min-width: 768px), print {
  .u-hide-tab-pc {
    display: none;
  }
}
/* タブレットのみ非表示 */
@media all and (min-width: 768px) and (max-width: 1200px) {
  .u-hide-tab {
    display: none;
  }
}
/* SPで非表示 */
@media all and (max-width: 767px) {
  .u-hide-sp {
    display: none;
  }
}
/* --------------------------------------------------------------------------
  行揃え
-------------------------------------------------------------------------- */
.u-center {
  text-align: center;
}
/* TAB,PCのみテキストセンター */
@media all and (min-width: 768px), print {
  .u-center-tab-pc {
    text-align: center;
  }
}
/* SPのみテキストセンター */
@media all and (max-width: 767px) {
  .u-center-sp {
    text-align: center;
  }
}
.u-justify {
  text-align: justify;
  text-justify: inter-ideograph;
}
/* --------------------------------------------------------------------------
  強調
-------------------------------------------------------------------------- */
.u-strong {
  /*color: #FF0000;*/
  font-weight: bold;
}
.u-em {
  font-weight: bold;
}
.u-bold {
  font-weight: bold;
}

body{
  font-feature-settings: 'palt';
}
body,
button,
input,
optgroup,
select,
textarea {
  font-family: YuGothic, 'Yu Gothic Medium', 'Yu Gothic', 'Segoe UI', Meiryo, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: YuGothic, 'Yu Gothic', 'Segoe UI semibold', 'Segoe UI', Meiryo, sans-serif;
}
.u-strong,
.u-em,
.u-bold{
  font-family: YuGothic, 'Yu Gothic', 'Segoe UI semibold', 'Segoe UI', Meiryo, sans-serif;
}

img{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* --------------------------------------------------------------------------
  ROOT FONT SIZE
-------------------------------------------------------------------------- */
/* 16px */
html {
  font-size: 16px;
}
body {
  font-size: 1rem;
}
@media all and (min-width: 1201px) and (max-width: 1366px) {
  /* 15px */
  html {
    font-size: 15px;
  }
}
@media all and (min-width: 981px) and (max-width: 1200px) {
  /* 14px */
  html {
    font-size: 13.5px;
  }
}
@media all and (min-width: 768px) and (max-width: 980px) {
  /* 13.5px */
  html {
    font-size: 13px;
  }
}
@media all and (min-width: 640px) and (max-width: 767px) {
  /* 16px */
  html {
    font-size: 16px;
  }
}
@media all and (max-width: 639px) {
  /* 14px */
  html {
    font-size: 14px;
  }
}
@media all and (max-width: 374px) {
  /* 13px */
  html {
    font-size: 13px;
  }
}


.content-container {
  position: relative;
  overflow-x: hidden;
}
@media all and (min-width: 768px), print {
  .content-container {
    min-width: 980px;
  }

  .sec {
  }
  .sec__in {
    max-width: 1465px;
    margin: 0 auto;
  }
  .sec__body {
    margin: 0 calc(166 / 1465 * 100%);
    padding: 0 1.625rem;
  }
}

@media all and (max-width: 767px) {
  .content-container {
    overflow-x: hidden;
  }
  .sec__in {
    margin: 0 calc(30 / 750 * 100%);
  }
}

/* --------------------------------------------------------------------------
  UTILITY LAYOUT
-------------------------------------------------------------------------- */


/* ==========================================================================
  MODAL
========================================================================== */

/* ==========================================================================
  HEADER MENU
========================================================================== */
@media all and (min-width: 768px), print {
  .gheader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 80px;
    height: 80px;
  }
  .gheader-humberger {
    position: absolute;
    top:50%;
    left: 50%;
    width: 40px;
    height: 22px;
    margin: -11px 0 0 -20px;
  }
  .gheader-humberger__btn {
    display: block;
  }
  .gheader-humberger__body {
    position: relative;
    display: block;
    width: 40px;
    height: 22px;
  }
  .gheader-humberger__bar:nth-child(1){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .gheader-humberger__bar:nth-child(1){
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .gheader-humberger__bar:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .gheader-humberger__bar:nth-child(3){
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
}
@media all and (max-width: 767px) {
  .gheader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 64px;
    height: 64px;
  }
  .gheader-humberger {
    position: absolute;
    top:50%;
    left: 50%;
    width: 34px;
    height: 18px;
    margin: -9px 0 0 -17px;
  }
  .gheader-humberger__btn {
    display: block;
  }
  .gheader-humberger__body {
    position: relative;
    display: block;
    width: 34px;
    height: 18px;
  }
  .gheader-humberger__bar:nth-child(1){
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .gheader-humberger__bar:nth-child(2){
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  .gheader-humberger__bar:nth-child(3){
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
}
/* ==========================================================================
  FOOTER
========================================================================== */
@media all and (min-width: 768px), print {
  .gfooter {
    background: #ededed;
  }
  .gfooter__in {
  }
  .gfooter__body {
    display: flex;
    justify-content: space-between;
    padding-top: calc(35/1081*100%);
    padding-bottom: calc(30/1081*100%);
  }
  .gfooter-main {
  }
  .gfooter-nav {
    display: flex;
  }
  .gfooter-nav__item {
    margin-right: 3.2rem;
    font-size: .875rem;
    letter-spacing: 0.06em;
  }
  .gfooter-main__note {
    margin-top: 2.275rem;
    font-size: .625rem;
    letter-spacing: 0.06em;
  }
  .gfooter-sub {
  }
  .gfooter-sub__logo {
    width: 11.9375rem;
    margin-top: -0.5rem;
    margin-left: auto;
  }
  .gfooter-sub__logo img{
    backface-visibility: hidden;
  }
  .gfooter-sub__copyright {
    margin-top: 0.85rem;
    font-size: .75rem;
    letter-spacing: 0.04em;
  }
  .no-touchevents .gfooter-nav__item a{
    transition: color 0.15s linear;
  }
  .no-touchevents .gfooter-nav__item a:hover{
    color: #5e5e5e;
  }
}
@media all and (max-width: 767px) {
  .gfooter {
    background: #ededed;
  }
  .gfooter__in {
  }
  .gfooter__body {
    padding-top: calc(110/690*100%);
    padding-bottom: calc(92/690*100%);
  }
  .gfooter-main {
  }
  .gfooter-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .gfooter-nav__item {
    margin:0  1.07142857rem;
    font-size:.92857143rem;
    letter-spacing: 0.06em;
  }
  .gfooter-nav__item:nth-child(n+3){
    margin-top: 2rem;
  }
  .gfooter-main__note {
    margin-top: 2.275rem;
    font-size: .85714286rem;
    line-height: 1.75;
    letter-spacing: 0.06em;
    text-align: center;
  }
  .gfooter-sub {
    margin-top: calc(148/690*100%);
  }
  .gfooter-sub__logo {
    width: 11.9375rem;
    margin: 0 auto;
  }
  .gfooter-sub__logo img{
    backface-visibility: hidden;
  }
  .gfooter-sub__copyright {
    margin-top: 1.5rem;
    font-size: .85714286rem;
    text-align: center;
  }
}
.js-page-top-show .page-top-btn{
  opacity: 1;
}
.page-top-btn{
  opacity: 0;
  transition: all 0.2s ease-out;
}
@media all and (min-width: 768px), print {
  .page-top-btn{
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 10;
    width: 4.5625rem;
    height:  4.5625rem;
  }
  .page-top-btn a{
    position: relative;
    display: block;
    width: 4.5625rem;
    height:  4.5625rem;
  }
  .page-top-btn a img{
    position: relative;
    backface-visibility: hidden;
  }
  .page-top-btn a::before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 4.125rem;
    height: 4.125rem;
    margin: auto;
    background-color: #edf7f5;
    border-radius: 50%;
    content: '';
  }
}
@media all and (max-width: 767px) {
  .page-top-btn{
    position: fixed;
    right: .64285714rem;
    bottom: .64285714rem;
    z-index: 10;
    width: 4.28571429rem;
    height:  4.28571429rem;
  }
  .page-top-btn a{
    position: relative;
    display: block;
    width: 4.28571429rem;
    height:  4.28571429rem;
  }
  .page-top-btn a img{
    position: relative;
    backface-visibility: hidden;
  }
  .page-top-btn a::before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 3.92857143rem;
    height: 3.92857143rem;
    margin: auto;
    background-color: #edf7f5;
    border-radius: 50%;
    content: '';
  }
}


/* ==========================================================================
  MODAL
========================================================================== */
html.is-modal-opened {
  overflow: hidden;
}
.js-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -19999;
}
.is-modal-opened .js-modal-container.is-modal-shown {
  z-index: 19999;
}
.js-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  visibility: hidden;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transition-property: visibility;
}
.js-modal.is-modal-active {
  visibility: visible;
}
.js-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  opacity: 0;
  transition-delay: 0;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transition-property: opacity;
  will-change: opacity;
}
.is-modal-active .js-modal-bg {
  z-index: 1;
  opacity: 1;
  transition-delay: 0s;
}
.js-modal-body {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transition-property: opacity;
}
.is-modal-active .js-modal-body {
  opacity: 1;
  transition-delay: 0.1s;
}
.js-modal-scroll {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media all and (min-width: 768px), print {
  /* .modal-chart__main-bg {
    display: flex;
    align-items: center;
    height: 100%;
  } */
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
  }
  .modal-close__btn {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    appearance: none;
  }
  .modal-close__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 22px;
    margin: auto;
  }
  .modal-close__body i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 22px;
    margin: auto;
  }
  .modal-close__body i::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transform: rotate(-25deg);
    content: '';
  }
  .modal-close__body i::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transform: rotate(25deg);
    content: '';
  }
}
@media all and (max-width: 767px) {
  .js-modal-scroll {
    width: 100%;
  }
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
  }
  .modal-close__btn {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    appearance: none;
  }
  .modal-close__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 22px;
    margin: auto;
  }
  .modal-close__body i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 22px;
    margin: auto;
  }
  .modal-close__body i::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transform: rotate(-25deg);
    content: '';
  }
  .modal-close__body i::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transform: rotate(25deg);
    content: '';
  }
}
@media all and (min-width: 768px), print {
  .modal-gnav__main-bg{
    height: 100%;
  }
  .modal-gnav-main{
    height: 100%;
  }
  .modal-gnav-main__in {
    max-width: 1680px;
    height: 100%;
    margin: 0 auto;
  }
  .modal-gnav-main__body {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .modal-gnav-header {
    width: calc(145 / 1465 * 100%);
  }
  .modal-gnav-header__in{
    position: relative;
    height: 100%;
  }
  .modal-gnav-header__logo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: calc(110 / 145 * 100%);
    margin: calc(19 / 145 * 100%) auto 0 auto;
  }

  .modal-gnav-body{
    display: flex;
    align-items: center;
    width: calc(1175 / 1465 * 100%);
    height: 100%;
  }
  .modal-gnav-body__in{
    max-width: 1077px;
    margin: 0 auto;

  }

  .modal-gnav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2.5rem;
    border-bottom: #000 1px solid;
  }
  .modal-gnav-list__item {
    width: 100%;
    text-align: center;
  }
  .modal-gnav-list__item + .modal-gnav-list__item{
    margin-top: 3.125rem;
  }
  .modal-gnav-list__item--half {
    width: 150px;
  }
  .modal-gnav-list__text-btn {
    font-size: 1.25rem;
  }
  .modal-gnav-list__text {
    font-size: 1.25rem;
  }
  .modal-gnav-lineup {
    margin: 0 10px;
    margin-top: 0.5rem;
  }
  .modal-gnav-lineup__in {
    display: flex;
  }
  .modal-gnav-lineup__item {
    width: calc(100 / 7%);
  }
  .modal-gnav-lineup__btn {
  }
  .modal-gnav-lineup__fig {
    width: calc(130 / 151 * 100%);
    margin: 0 auto;
  }
  .modal-gnav-lineup__label {
    margin-top: .625rem;
    font-size: .875rem;
    line-height: 1.5;
  }

  .modal-gnav-onlineshop {
    margin-top: 2.8125rem;
  }
  .modal-gnav-onlineshop__in {
    display: flex;
    justify-content: center;
  }
  .modal-gnav-onlineshop__item {
    margin: 0 2.1875rem;
  }
  .modal-gnav-onlineshop__item img{
    backface-visibility: hidden;
  }
  .modal-gnav-onlineshop__item--amazon {
    width: 3.75rem;
  }
  .modal-gnav-onlineshop__btn {
  }
  .modal-gnav-onlineshop__item--rakuten {
    width: 3.75rem;
  }
  .modal-gnav-onlineshop__item--paypay {
    width: 7.1875rem;
  }
  .modal-gnav-onlineshop__item--naturelab {
    width: 6.1875rem;
  }

  .modal-gnav-sns {
    margin-top: 3.125rem;
  }
  .modal-gnav-sns__in {
    display: flex;
    justify-content: center;
  }
  .modal-gnav-sns__item {
    width: 1.3125rem;
    height: 1.3125rem;
    margin: 0 1.125rem;
  }

  .modal-gnav-footer{
    width: calc(145 / 1465 * 100%);
    height: 100%;
  }
}
@media all and (max-width: 767px) {
  .modal-gnav__main-bg{
  }
  .modal-gnav-main{
  }
  .modal-gnav-main__in {
    margin: 0 auto;
  }
  .modal-gnav-main__body {
  }
  .modal-gnav-header {
  }
  .modal-gnav-header__in{
    position: relative;
    height: 100%;
  }
  .modal-gnav-header__logo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    width: calc(132/750*100%);
    margin-top: calc(30/750*100%);
    margin-left: calc(18/750*100%);
  }
  .modal-gnav-body{
    padding-top: calc(144/750*100%);
    padding-bottom: calc(120/750*100%);
  }
  .modal-gnav-body__in{
  }
  .modal-gnav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 1rem;
    padding-bottom: 2.5rem;
    border-bottom: #000 1px solid;
  }
  .modal-gnav-list__item {
    width: 100%;
    text-align: center;
  }
  .modal-gnav-list__item + .modal-gnav-list__item{
    margin-top: 3.125rem;
  }
  .modal-gnav-list__item--half {
    width: 150px;
  }
  .modal-gnav-list__text-btn {
    font-size: 1.21428571rem;
  }
  .modal-gnav-list__text {
    font-size: 1.21428571rem;
  }
  .modal-gnav-lineup {
    margin-top: 1.5rem;
  }
  .modal-gnav-lineup__in {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .modal-gnav-lineup__item {
    width: 25%;
  }
  .modal-gnav-lineup__item:nth-child(n+5) {
    margin-top: 1.07142857rem;
  }
  .modal-gnav-lineup__btn {
  }
  .modal-gnav-lineup__fig {
    width: calc(130 / 151 * 100%);
    margin: 0 auto;
  }
  .modal-gnav-lineup__label {
    margin-top: .625rem;
    font-size: .85714286rem;
    line-height: 1.4;
  }
  .modal-gnav-onlineshop {
    margin-top: 2.8125rem;
  }
  .modal-gnav-onlineshop__in {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:calc(640/750*100%);
    margin: 0 auto;
  }
  .modal-gnav-onlineshop__item {
    width: 50%;
  }
  .modal-gnav-onlineshop__item:nth-child(n+3){
    margin-top: 2.5rem;
  }
  .modal-gnav-onlineshop__item img{
    backface-visibility: hidden;
  }
  .modal-gnav-onlineshop__item--amazon a{
    width: calc(158/320*100%);
  }
  .modal-gnav-onlineshop__item--rakuten a{
    width: calc(157/320*100%);
  }
  .modal-gnav-onlineshop__item--paypay a{
    width: calc(244/320*100%);
  }
  .modal-gnav-onlineshop__item--naturelab a{
    width: calc(223/320*100%);
  }
  .modal-gnav-onlineshop__btn {
    display: block;
    margin: 0 auto;
  }

  .modal-gnav-sns {
    margin-top: 3.125rem;
  }
  .modal-gnav-sns__in {
    display: flex;
    justify-content: center;
  }
  .modal-gnav-sns__item {
    width: 1.78571429rem;
    height: 1.78571429rem;
    margin: 0 1.125rem;
  }
  .modal-gnav-footer{
    width: calc(145 / 1465 * 100%);
    height: 100%;
  }
}
